<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-adicionar to="/alertas-perguntas/adicionar" />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="alertasPerguntas"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="carregarAlertasPerguntas"
          >
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status == 1 ? 'Ativo' : 'Inativo' }}
            </template>

            <template v-slot:[`item.obrigatoriedade_pergunta`]="{ item }">
              {{ item.obrigatoriedade_pergunta == 1 ? 'Sim' : 'Não' }}
            </template>

            <template
              v-slot:[`item.actions`]="{ item }"
              class="text-right"
            >
              <table-btn-editar :href="`/alertas-perguntas/${item.id}/editar`" />
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import alertasPerguntasApi from '@/api/alertas-perguntas'

  export default {

    data () {
      return {
        carregando: false,
        totalRegistros: 0,
        idExcluir: null,
        cabecalho: [
          { text: '#', value: 'id' },
          { text: 'Descrição', value: 'texto_pergunta' },
          { text: 'Obrigatória?', value: 'obrigatoriedade_pergunta' },
          { text: 'Status', value: 'status' },
          { text: 'Ações', value: 'actions', align: 'right' },
        ],
        alertasPerguntas: [],
        paginacao: {},
      }
    },

    methods: {
      async carregarAlertasPerguntas () {
        try {
          this.carregando = true

          const resposta = await alertasPerguntasApi.listar({
            pagina: this.paginacao.page,
            por_pagina: this.paginacao.itemsPerPage,
          })

          this.alertasPerguntas = resposta.data.alertas_perguntas
          this.totalRegistros = resposta.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },

  }
</script>
